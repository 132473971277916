import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Img, Container, Loading } from "./Style"

export const Gif = () => {
  const data = useStaticQuery(graphql`
    query {
      gif: file(relativePath: { eq: "loader.gif" }) {
        publicURL
      }
    }
  `)
  return <Img src={data.gif.publicURL} />
}

export default function Loader() {
  return (
    <Container>
      <Gif />
      <Loading>Laddar&hellip;</Loading>
    </Container>
  )
}

import styled from "styled-components"
import { sidePadding } from "../Style"
import { Text } from "../Text"

export const Img = styled.img`
  width: 3rem;
  margin-top: ${sidePadding * 2}rem;
  margin-bottom: ${sidePadding}rem;
`

export const Container = styled.div`
  margin: auto;
  text-align: center;
`

export const Loading = styled(Text)`
  margin-bottom: ${sidePadding * 2}rem;
`

import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import SEO from "../components/seo"
import { Block } from "../components/Block"
import Loader from "../components/loader"
import { useStateValue } from "../state"
import FormEdit from "../components/form/edit"

const Edit = ({ pageContext, location, ...props }) => {
  const [, dispatch] = useStateValue()
  // get pathname and URL params
  const { pathname, search } = location
  // get post (to get the post data) & posts (to build the selects) from pageContext
  const { postID, models: mdls } = pageContext
  // rearrange the models
  const models = mdls.map(m => ({ value: m.id, name: m.title }))
  // is the page allowed the show content?
  const [isAllowed, setIsAllowed] = useState(false)
  // has axios requested
  const [hasRequested, setHasRequested] = useState(false)
  // show form
  const [showForm, setShowForm] = useState(false)
  // extract key from URL param
  const [keyFromParam] = useState(new URLSearchParams(search).get("key"))

  // reset form when user is sent somewhere else
  useEffect(() => () => dispatch({ type: "EDIT_RESET" }), [dispatch])

  // first let's check if the user is allowed to view this page
  useEffect(() => {
    if (!isAllowed) {
      const redirectToPost = () =>
        navigate(pathname.substring(0, pathname.lastIndexOf("/")))
      // check if their's a key in the search param
      if (keyFromParam === "" || !keyFromParam) {
        // there aren't any, redirect to post
        redirectToPost()
      } else {
        axios
          .post(`${process.env.GATSBY_API_URL}/api/posts/${postID}/auth`, {
            key: keyFromParam,
          })
          .then(({ data }) =>
            data.result ? setIsAllowed(true) : redirectToPost()
          )
      }
    }
  }, [isAllowed, keyFromParam, pathname, postID])

  useEffect(() => {
    if (!hasRequested && isAllowed) {
      // now the user should be allowed to see the page and has the correct key.
      // it's time to fetch the data?
      setHasRequested(true)

      // get data for post from backend (not build!)
      axios
        .get(`${process.env.GATSBY_API_URL}/api/posts/${postID}`)
        .then(({ data }) => {
          // populate form
          dispatch({
            type: "EDIT_UPDATE",
            payload: {
              id: data.id,
              image: data.images[0].path,
              model: data.model_name.id,
              term: data.term,
              year: data.year,
              description: data.description,
              machineName: data.nick_name,
              city: data.city,
              identifier: keyFromParam,
              models,
            },
          })

          setShowForm(true)
        })
    }
  }, [hasRequested, isAllowed, dispatch, keyFromParam])

  return (
    <>
      <SEO title={`Redigera din epiroc`} />
      <Block>{showForm ? <FormEdit /> : <Loader />}</Block>
    </>
  )
}

export default Edit

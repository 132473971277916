import React, { useRef, useEffect } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import useMobile from "../../../lib/useMobile"
import { useStateValue } from "../../../state"
import { Title } from "../../form/header/Style"
import Hr from "../../hr"
import Select from "../../select"
import { Input, Textarea, File, ImageUploader } from "../../input"
import Checkbox from "../../checkbox"
import { Container as Actions } from "../actions/Style"
import Button from "../../button"
import { ObjectWithoutKey, hasKey } from "../../../utils"
import ErrorSummary from "../error"

export default function FormEdit({ ...props }) {
  const [state, dispatch] = useStateValue()
  const [isMobile] = useMobile()
  const formRef = useRef()
  const {
    submit,
    errors,
    values: {
      id,
      models,
      identifier,
      city,
      machineName,
      description,
      year,
      term,
      model,
      accept,
      image,
      file,
    },
  } = state.edit

  const handleErrorInput = name => {
    if (errors.hasOwnProperty(name)) {
      dispatch({
        type: "EDIT_ERROR_UPDATE",
        payload: ObjectWithoutKey(errors, name),
      })
    }
  }

  const handleOnChange = event => {
    const {
      target,
      target: { name, value },
    } = event

    let value2return

    if (target.type.match(/select/)) {
      value2return = parseFloat(value)
    } else if (target.type.match(/checkbox/)) {
      value2return = !accept
    } else if (target.type.match(/file/)) {
      value2return = target.files.length > 0 ? target.files[0] : ""
    } else {
      value2return = value
    }

    handleErrorInput(name)

    dispatch({
      type: "EDIT_UPDATE",
      payload: {
        [name]: value2return,
      },
    })
  }

  const handleErrors = err => {
    window.scroll({
      top: formRef.current.scrollTop,
      behavior: "smooth",
    })

    dispatch({
      type: "EDIT_ERROR_SET",
      payload: err.response.data.errors,
    })
  }

  useEffect(() => {
    if (submit) {
      const formData = new FormData()

      Object.entries({
        identifier,
        city,
        machineName,
        description,
        year,
        term,
        model,
        accept,
        file,
      }).forEach(([key, val]) => {
        formData.append(key, val)
      })

      axios({
        method: "post",
        data: formData,
        url: `${process.env.GATSBY_API_URL}/api/posts/${id}`,
      })
        .then(() => navigate("/success"))
        .catch(err => {
          dispatch({ type: "EDIT_SET", payload: { submit: false } })
          hasKey(err.response, "data") && handleErrors(err)
        })
    }
  }, [
    dispatch,
    submit,
    id,
    identifier,
    city,
    machineName,
    description,
    year,
    term,
    model,
    accept,
    file,
  ])

  useEffect(() => {
    // keep reference of form
    dispatch({ type: "EDIT_SET", payload: { form: formRef.current } })
  }, [dispatch])

  return (
    <>
      <Title>Redigera din Epiroc</Title>
      <Hr />
      <ErrorSummary errors={errors} />
      <form autoComplete="off" ref={formRef}>
        <ImageUploader
          src={image}
          hasUpload={file !== ""}
          onCancel={() => {
            dispatch({ type: "EDIT_UPDATE", payload: { file: "" } })
          }}
        >
          <File
            name="file"
            onChange={handleOnChange}
            filename={file ? file.name.split("\\").pop() : null}
            label="Ändra bilden"
            description="(Maxstorlek 2mb. godkända format .pdf, .png, .jpeg)"
            accept="image/*"
            errors={errors}
            required
          />
        </ImageUploader>
        <Select
          onChange={handleOnChange}
          value={model}
          name="model"
          label="Masskinserie för din Epiroc"
          description="Byt serienamn."
          options={models}
          errors={errors}
          required
        />
        <Input
          onChange={handleOnChange}
          value={term}
          name="term"
          label="Maskinmodell"
          description="Byt modellbeteckning för din Epiroc."
          errors={errors}
          required
        />
        <Input
          onChange={handleOnChange}
          value={year}
          name="year"
          label="Årsmodell"
          description="Byt årsmodellet för din Epiroc."
          pattern="[0-9.]+"
          maxLength="4"
          errors={errors}
          required
        />
        <Textarea
          onChange={handleOnChange}
          value={description}
          name="description"
          label="Beskrivning"
          description="Byt beskrivningen av din Epiroc."
          errors={errors}
          required
        />
        <Input
          name="machineName"
          value={machineName}
          onChange={handleOnChange}
          label="Maskinens namn"
          description="Min Epiroc heter/Namnförslag"
          generator={"true"}
          errors={errors}
          required
        />
        <Input
          name="city"
          value={city}
          onChange={handleOnChange}
          label="Ort"
          description="Jag och min Epiroc bor i&hellip;"
          errors={errors}
          required
        />
        <Checkbox
          name="accept"
          checked={accept}
          onChange={handleOnChange}
          label="Jag godkänner att Epiroc lagrar mina personuppgifter i marknadsföringssyfte."
          errors={errors}
          required
        />
        {!isMobile && (
          <Actions>
            <Button
              type="submit"
              disabled={submit}
              icon={{
                name: ["far", "arrow-right"],
                position: "right",
              }}
              onClick={e => {
                const isValid = formRef.current.reportValidity()

                if (isValid) {
                  e.preventDefault()
                  dispatch({ type: "EDIT_SUBMIT" })
                }
              }}
            >
              {submit ? "Skickar…" : "Skicka"}
            </Button>
          </Actions>
        )}
      </form>
    </>
  )
}
